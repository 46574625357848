<div>

<div id="space">
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div> 
</div> 

<div class='logo'>
	<img src='assets/GK_logo_white_on_black_large.png' />
</div>

<div class='intro'>
  Sixty One Moons is an eleven track album by three mysterious guys from Canada, Poland & UK. 
</div>

<div class='album'>
<div class='album-cover'><img src='/assets/61MoonsCover.png' /></div>
<div class='lp_spin'>
  <div class='lp_spin_label'></div>
</div>
<div class='album-back'><img src='/assets/61MoonsCover_Back.png' /></div>
</div>

<div class='intro'>
  You can play the full Sixty One Moons album here on...
  
  <a href='https://www.youtube.com/watch?v=YDggzeLTmqY'>YouTube<img src='/assets/youtube-music.png' /></a>

  Or stream on your favourite service...<br />
  <a href='https://open.spotify.com/album/63fqmJwCNTckLlA4Fn6oAm'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
  <a href='https://music.apple.com/us/album/sixty-one-moons/1502798673'>Stream on Apple Music<img src='/assets/apple-music.png' /></a>
  <a href='https://tidal.com/browse/album/134355385'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
  <a href='https://www.deezer.com/en/album/136413812'>Stream on Deezer<img src='/assets/deezer-music.png' /></a>
  <a href='https://audius.co/ghostkollective/album/sixty-one-moons-31411'>Stream on Audius<img src='/assets/audius-music.png' /></a>
</div>

<!-- div class='video'>
  <iframe src="https://www.youtube.com/embed/YDggzeLTmqY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div -->

<div class='intro'>
  You can also play or stream individual tracks from the album...
</div>

<div class='vid-grid'>
  <div class='vid-grid-1'>
    <div class='video'>
      1. Sunshine Singularity
      <img src='/assets/sunshine-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=aNpnKotTkvs'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/1SVCdSMAXNaTshGnXzocjK?si=07093533e6c248a5'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355386'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/sunshine-singularity-280417'>Stream on Audius<img src='/assets/audius-music.png' /></a>

    </div>
  </div>
  <div class='vid-grid-2'>
    <div class='video'>
      2. Ad Astra
      <img src='/assets/astra-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=b3JpdrXgwk8'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/6VJgwJXkksaDbHw1ZdCvWa?si=fc12b83f927c4b3f'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355387'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/ad-astra-280425'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-3'>
    <div class='video'>
      3. Silicon Junkies
      <img src='/assets/silicon-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=5mPSDioquBg'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/4SMfLLCq8xgdfr3JslvXWr?si=cea05338d02d4f79'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355388'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/silicon-junkies-280424'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-4'>
    <div class='video'>
      4. Flesh And Bones
      <img src='/assets/flesh-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=LN6d9j-p0nA'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/5IHm75WVWwEs6Da18Y5qzy?si=871f96275cd747bd'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355389'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/flesh-and-bones-280427'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
</div>

<div class='vid-grid'>
  <div class='vid-grid-1'>
    <div class='video'>
      5. One Time Machine
      <img src='/assets/onetime-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=L0LVN2P2BdM'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/26XmNZ8Cb2EZr59kUdPWFj?si=4c8a6b45c34c4117'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355390'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/one-time-machine-280420'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-2'>
    <div class='video'>
      6. Ulkoavaruus
      <img src='/assets/ulko-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=iCTmiNJb9QY'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/3hX5fv09HJNQP9kFPvZUdw?si=6c7685fa12c74a91'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355391'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/ulkoavaruus-280421'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-3'>
    <div class='video'>
      7. Eniac's Ghost
      <img src='/assets/eniac-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=Kd-TvrtFMQg'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/07RfnePwr5F78mfCl6kANU?si=c50a05add1f445f5'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355392'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/eniacs-ghost-280423'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-4'>
    <div class='video'>
      8. Gravity
      <img src='/assets/gravity-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=F9nIMh26T8Y'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/0G3UuAjgIelW6dB9ptDMmM?si=257a159ee49644ec'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355393'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/gravity-280419'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
</div>


<div class='vid-grid'>
  <div class='vid-grid-1'>
    <div class='video'>
      9. Friend Or Foe
      <img src='/assets/friend-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=YDggzeLTmqY'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/02A8izri8mpnxMMx4doEW6?si=ef10deddd3ff4464'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355394'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/friend-or-foe-280422'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-2'>
    <div class='video'>
      10. Everything Is Light
      <img src='/assets/light-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=cmyEj_kRv1w'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/4szblhooaeiWd1coRrsxb5?si=d756d4e764c3419b'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355395'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/everything-is-light-280418'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-3'>
    <div class='video'>
      11. Back To Earth
      <img src='/assets/back2-thumb.png' class='video-thumb' />
      <a href='https://www.youtube.com/watch?v=WquQcVqo1MU'>Play on Youtube<img src='/assets/youtube-music.png' /></a>      
      <a href='https://open.spotify.com/track/6BV5PJkko3MWGIiW4P1oq2?si=a68bafde19c9483c'>Stream on Spotify<img src='/assets/spotify-music.png' /></a>
      <a href='https://tidal.com/browse/track/134355396'>Stream on Tidal<img src='/assets/tidal-music.png' /></a>
      <a href='https://audius.co/ghostkollective/back-to-earth-280426'>Stream on Audius<img src='/assets/audius-music.png' /></a>
    </div>
  </div>
  <div class='vid-grid-4'>
    <div class='video'>
    </div>
  </div>
</div>



<router-outlet></router-outlet>
</div>